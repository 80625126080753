import * as actionTypes from '../actions/actionTypes';

const initialState = {
  resultAnalytics: {},
  error: null,
  loading: false,
};

const resultReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RESULT_ANALYTICS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_RESULT_ANALYTICS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case actionTypes.GET_RESULT_ANALYTICS_SUCCESS:
      return {
        ...state,
        resultAnalytics: action.payload.result,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default resultReducer;
