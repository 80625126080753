export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const GET_ATO_START = 'GET_ATO_START';
export const GET_ATO_SUCCESS = 'GET_ATO_SUCCESS';
export const GET_ATO_FAIL = 'GET_ATO_FAIL';

export const GET_APPROVALS_START = 'GET_APPROVALS_START';
export const GET_APPROVALS_SUCCESS = 'GET_APPROVALS_SUCCESS';
export const GET_APPROVALS_FAIL = 'GET_APPROVALS_FAIL';

export const GET_SUMMARY_START = 'GET_SUMMARY_START';
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
export const GET_STUDENT_SUMMARY_SUCCESS = 'GET_STUDENT_SUMMARY_SUCCESS';
export const GET_TEACHERS_SUMMARY_SUCCESS = 'GET_TEACHERS_SUMMARY_SUCCESS';
export const GET_SUMMARY_FAIL = 'GET_SUMMARY_FAIL';

export const GET_ATTENDANCE_ANALYTICS_START = 'GET_ATTENDANCE_ANALYTICS_START';
export const GET_ATTENDANCE_ANALYTICS_FAIL = 'GET_ATTENDANCE_ANALYTICS_FAIL';
export const GET_ATTENDANCE_ANALYTICS_SUCCESS =
  'GET_ATTENDANCE_ANALYTICS_SUCCESS';

export const GET_RESULT_ANALYTICS_START = 'GET_RESULT_ANALYTICS_START';
export const GET_RESULT_ANALYTICS_FAIL = 'GET_RESULT_ANALYTICS_FAIL';
export const GET_RESULT_ANALYTICS_SUCCESS = 'GET_RESULT_ANALYTICS_SUCCESS';
