import React, { useState } from 'react';
import './collapsible.scss';
import ai from '../../assets/ai.png';
import { Collapse } from 'antd';
import InsertIcon from '../../icons/InsertIcon';

const RIghtArrow = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_26_272)'>
        <path
          d='M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z'
          fill='#010C15'
          filOpacity='0.7'
        />
      </g>
      <defs>
        <clipPath id='clip0_26_272'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='matrix(0 -1 1 0 0 24)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const DownArrow = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_26_296)'>
        <path
          d='M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z'
          fill='#010C15'
          fillOpacity='0.7'
        />
      </g>
      <defs>
        <clipPath id='clip0_26_296'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
const Collapsible = ({ title, subResult, p1, p2 }) => {
  const [collapse, setCollapse] = useState(false);
  return (
    <>
      <div className='collapsible-wrapper'>
        <h3>{title}</h3>
        <button onClick={() => setCollapse(!collapse)}>
          {collapse ? <DownArrow /> : <RIghtArrow />}
        </button>
      </div>
      {collapse && (
        <div className='collapsible-wrapper__content'>
          <h3 className='collapsible-wrapper__content__title'>
            <img src={ai} alt='' />
            AI Assistant Recommendation for Government and Policy Makers
          </h3>
          <p>{p1}</p>
          <ul>
            {subResult.map((el) => (
              <li>
                <h4>{el.heading}</h4>
                <p>{el.body}</p>
              </li>
            ))}
          </ul>
          <p>{p2}</p>
          <button className='download-btn'>
            <InsertIcon />
            Download PDF
          </button>
        </div>
      )}
    </>
  );
};

export default Collapsible;
