import './button.scss';

const Button = ({ btnText, onClick, btnClass, disabled, type }) => {
  return (
    <button
      onClick={onClick}
      className={btnClass}
      disabled={disabled}
      type={type}
    >
      {btnText}
    </button>
  );
};

export default Button;
