import React from 'react';

const Clear = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_290_5520)'>
        <path
          d='M12.6673 4.2735L11.7273 3.3335L8.00065 7.06016L4.27398 3.3335L3.33398 4.2735L7.06065 8.00016L3.33398 11.7268L4.27398 12.6668L8.00065 8.94016L11.7273 12.6668L12.6673 11.7268L8.94065 8.00016L12.6673 4.2735Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_290_5520'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clear;
