import React from 'react';

const More = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.7' clip-path='url(#clip0_284_5497)'>
        <path
          d='M7.99935 5.33317C8.73268 5.33317 9.33268 4.73317 9.33268 3.99984C9.33268 3.2665 8.73268 2.6665 7.99935 2.6665C7.26602 2.6665 6.66602 3.2665 6.66602 3.99984C6.66602 4.73317 7.26602 5.33317 7.99935 5.33317ZM7.99935 6.6665C7.26602 6.6665 6.66602 7.2665 6.66602 7.99984C6.66602 8.73317 7.26602 9.33317 7.99935 9.33317C8.73268 9.33317 9.33268 8.73317 9.33268 7.99984C9.33268 7.2665 8.73268 6.6665 7.99935 6.6665ZM7.99935 10.6665C7.26602 10.6665 6.66602 11.2665 6.66602 11.9998C6.66602 12.7332 7.26602 13.3332 7.99935 13.3332C8.73268 13.3332 9.33268 12.7332 9.33268 11.9998C9.33268 11.2665 8.73268 10.6665 7.99935 10.6665Z'
          fill='#010C15'
        />
      </g>
      <defs>
        <clipPath id='clip0_284_5497'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default More;
