import React from 'react';
import './confirmation.scss';
import { FcExpired } from 'react-icons/fc';

const Confirmation = ({ takeAction, cancel, id }) => {
  return (
    <div className='confirm'>
      <div className='confirm__img'>
        <FcExpired size={70} />
      </div>
      <h2>Revoke ATO</h2>
      <p>
        Are you sure you want to revoke this ATO?, Please note that this action
        is unreversible
      </p>
      <div className='confirm__btn-flex'>
        <button
          className='reject-button'
          style={{ background: 'rgba(75, 142, 100, 0.2)', color: '#4B8E64' }}
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </button>

        <button
          className='approve-button'
          onClick={() => {
            takeAction('revoke', id);
          }}
          style={{ background: '#FF0000', color: '#fff' }}
        >
          Revoke ATO
        </button>
      </div>
    </div>
  );
};

export default Confirmation;
