import axios from '../utils/axios';

export const getAtoRequests = async (status, page) => {
  const response = status
    ? await axios.get(`/admin/approval-requests?page=${page}&status=${status}`)
    : await axios.get(`/admin/approval-requests?page=${page}`);
  return response.data;
};

export const filterAtoRequests = async (
  page,
  status,
  zone,
  lga,
  type,
  level
) => {
  const response = await axios.get(
    `/admin/approval-requests?page=${page}${status ? `&status=${status}` : ''}${
      zone ? `&zone=${zone}` : ''
    } ${lga ? `&lga=${lga}` : ''} 
    ${type ? `&school-type=${type}` : ''}
    ${level ? `&school-level=${level}` : ''}`.replace(/ /g, '')
  );

  return response.data;
};

export const approveAtoRequest = async (id) => {
  const response = await axios.put(`/admin/approval-requests/${id}/approve`);
  return response.data;
};

export const rejectAtoRequest = async (id, data) => {
  const response = await axios.put(
    `/admin/approval-requests/${id}/reject`,
    data
  );
  return response.data;
};

export const revokeAtoRequest = async (id) => {
  const response = await axios.put(`/admin/approvals/${id}/revoke`);
  return response.data;
};

export const unrevokeAtoRequest = async (id) => {
  const response = await axios.put(`/admin/approvals/${id}/revoke`);
  return response.data;
};

export const getApprovals = async (status, page) => {
  const response = await axios.get(
    `/admin/approvals?page=${page}${status ? `${status}` : ''}`.replace(
      / /g,
      ''
    )
  );

  return response.data;
};

export const filterApprovals = async (status, page, zone, type, lga, level) => {
  const response = await axios.get(
    `/admin/approvals?page=${page}${status ? `&status=${status}` : ''}${
      zone ? `&zone=${zone}` : ''
    } ${lga ? `&lga=${lga}` : ''} 
    ${type ? `&school-type=${type}` : ''}
    ${level ? `&school-level=${level}` : ''}`.replace(/ /g, '')
  );

  return response.data;
};
