import * as services from '../../services/summaryService';
import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';

//auth dispatch
export const getStart = () => {
  return {
    type: actionTypes.GET_ATTENDANCE_ANALYTICS_START,
  };
};

export const getFail = (error) => {
  return {
    type: actionTypes.GET_ATTENDANCE_ANALYTICS_FAIL,
    payload: {
      error,
    },
  };
};

export const getSuccess = (attendance) => {
  return {
    type: actionTypes.GET_ATTENDANCE_ANALYTICS_SUCCESS,
    payload: {
      attendance,
    },
  };
};

//actions
export const getAttendanceAnalytics = () => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.getAttendanceAnalytics();
      dispatch(getSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(error?.response?.data?.data?.error || 'Unable to fetch data');
      dispatch(getFail(error.response.data.data.error));
    }
  };
};
