import React from 'react';
import './legend.scss';

const CustomLegend = ({ data }) => {
  return (
    <div className='custom-legend-wrapper'>
      {data?.map((el) => (
        <div className='custom-legend-wrapper__wrap' key={el.id}>
          <div className='legend-box' style={{ background: el.bgColor }}></div>
          <p>{el.label}</p>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
