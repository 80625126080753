import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import './recommendation.scss';
import Collapsible from '../../components/collapsible/Collapsible';
import { recommendations } from '../../data';

const Recommendation = () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleButtonClick = () => {
    setLoading(true);

    // Simulate an API call with a 5-second delay
    setTimeout(() => {
      setLoading(false);
      setResults(recommendations);
    }, 5000);
  };

  return (
    <PageLayout currentPage={'Recommendation'}>
      <div className='recommendation-wrapper'>
        <h3 className='recommendation-wrapper__title'>
          AI Assistant Recommendation for Government and Policy Makers
        </h3>
        <p className='recommendation-wrapper__subtitle'>
          Select the class and subject you want recommendations for{' '}
        </p>

        <div className='recommendation-wrapper__selection'>
          <div className='recommendation-wrapper__selection__select'>
            <label>Class</label>
            <select>
              <option>SS3 WAEC</option>
            </select>
          </div>
          <div className='recommendation-wrapper__selection__select'>
            <label>Subject</label>
            <select>
              <option>English Language</option>
            </select>
          </div>
          <button
            className='recommendation-action'
            onClick={handleButtonClick}
            disabled={loading}
          >
            {loading
              ? 'Gathering recommendations...'
              : 'View AI Recommendation'}
          </button>
        </div>

        {results.length > 0 && (
          <>
            <div className='divider'></div>
            <div className='recommendation-result'>
              <h3>English Language</h3>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                {results.map((r) => (
                  <Collapsible
                    title={r.title}
                    key={r.id}
                    p1={r.p1}
                    p2={r.p2}
                    subResult={r.subResult}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default Recommendation;
