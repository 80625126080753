import * as services from '../../services/authService';
import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';

//auth dispatch
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    payload: {
      error,
    },
  };
};

export const authSuccess = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      data,
    },
  };
};

export const loginUser = (data, callback) => {
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const response = await services.login(data);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      localStorage.setItem('token', response.data.accessToken);
      localStorage.removeItem('information');
      toast.success(response.message);
      callback();
      dispatch(authSuccess(response?.data));
    } catch (error) {
      dispatch(
        authFail(
          error.response.data.message
            ? error.response.data.message
            : 'An error occured'
        )
      );
      toast.error(error.response.data.message);
    }
  };
};
