import * as services from '../../services/atoService';
import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';

//auth dispatch
export const getStart = () => {
  return {
    type: actionTypes.GET_ATO_START,
  };
};

export const getFail = (error) => {
  return {
    type: actionTypes.GET_ATO_FAIL,
    payload: {
      error,
    },
  };
};

export const getSuccess = (data) => {
  return {
    type: actionTypes.GET_ATO_SUCCESS,
    payload: {
      data,
    },
  };
};

//actions
export const getAtoRequests = (status, page) => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.getAtoRequests(status, page);
      dispatch(getSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error ||
          'Unable to fetch approval requests'
      );
      dispatch(getFail(error.response.data.data.error));
    }
  };
};

export const getApprovalStart = () => {
  return {
    type: actionTypes.GET_APPROVALS_START,
  };
};

export const getApprovalFail = (error) => {
  return {
    type: actionTypes.GET_APPROVALS_FAIL,
    payload: {
      error,
    },
  };
};

export const getApprovalSuccess = (data) => {
  return {
    type: actionTypes.GET_APPROVALS_SUCCESS,
    payload: {
      data,
    },
  };
};

//actions
export const getApprovals = (status, page) => {
  return async (dispatch) => {
    dispatch(getApprovalStart());
    try {
      const response = await services.getApprovals(status, page);
      dispatch(getApprovalSuccess(response.data));
    } catch (error) {
      dispatch(getApprovalFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch approvals'
      );
      dispatch(getApprovalFail(error.response.data.data.error));
    }
  };
};

export const filterApprovals = (status, page, zone, type, lga, level) => {
  return async (dispatch) => {
    dispatch(getApprovalStart());
    try {
      const response = await services.filterApprovals(
        status,
        page,
        zone,
        type,
        lga,
        level
      );
      dispatch(getApprovalSuccess(response.data));
    } catch (error) {
      dispatch(getApprovalFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch approvals'
      );
      dispatch(getApprovalFail(error.response.data.data.error));
    }
  };
};

export const filterAtoRequests = (page, status, zone, type, lga, level) => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.filterAtoRequests(
        page,
        status,
        zone,
        type,
        lga,
        level
      );
      dispatch(getSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch students list'
      );
      dispatch(getFail(error.response.data.data.error));
    }
  };
};
