import React from 'react';

const RadioUnchecked = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.00016 14.6667C4.31816 14.6667 1.3335 11.682 1.3335 8C1.3335 4.318 4.31816 1.33333 8.00016 1.33333C11.6822 1.33333 14.6668 4.318 14.6668 8C14.6668 11.682 11.6822 14.6667 8.00016 14.6667ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.771 13.3335 9.41448 13.3335 8C13.3335 6.58551 12.7716 5.22895 11.7714 4.22876C10.7712 3.22856 9.41465 2.66666 8.00016 2.66666C6.58567 2.66666 5.22912 3.22856 4.22893 4.22876C3.22873 5.22895 2.66683 6.58551 2.66683 8C2.66683 9.41448 3.22873 10.771 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333Z'
        fill='#020E24'
        fillOpacity='0.7'
      />
    </svg>
  );
};

export default RadioUnchecked;
