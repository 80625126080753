import { combineReducers } from 'redux';

import authReducer from './authReducer';
import atoReducer from './atoReducer';
import summaryReducer from './summaryReducer';
import attendanceReducer from './attendanceReducer';
import resultReducer from './resultReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  ato: atoReducer,
  summary: summaryReducer,
  attendance: attendanceReducer,
  results: resultReducer,
});

export default rootReducer;
