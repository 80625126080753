import axios from '../utils/axios';

export const getSummary = async (type) => {
  const response = await axios.get(`/admin/${type}/stats`);
  return response.data;
};

export const getAttendanceAnalytics = async () => {
  const response = await axios.get(`/admin/schools/attendance`);
  return response.data;
};

export const getResultAnalytics = async () => {
  const response = await axios.get(`/admin/schools/results`);
  return response.data;
};
