import { useState } from 'react';
import Button from '../../components/Button/Button';
import './login.scss';
import logo from '../../assets/ekiti.jpeg';
import TextInput from '../../components/Input/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleBlur = (evt) => {
    const value = evt.target.value;
    if (!value) {
      setErrors({
        ...errors,
        [evt.target.name]: `Field cannot be empty`,
      });
    } else {
      setErrors({
        ...errors,
        [evt.target.name]: ``,
      });
    }
  };

  const pushRoute = () => {
    setState({
      username: '',
      password: '',
    });

    window.location.replace('/summary');
  };

  const submit = () => {
    let data = {
      email: state.username,
      password: state.password,
    };

    dispatch(loginUser(data, pushRoute));
  };

  return (
    <div className='login'>
      <div className='login__card'>
        <div className='login__card__logo'>
          <img src={logo} alt='' width={70} height={70} />

          <h2>Ekiti State Ministry Of Education Dashboard</h2>
        </div>

        <div className='login__card__form'>
          <form>
            <TextInput
              label='Username/ Email Address'
              fieldClass={errors['username'] ? 'error-field' : 'input-field'}
              placeholder='edves123 or edves1@gmail.com'
              name='username'
              value={state.username}
              handleChange={handleChange}
              handleBlur={handleBlur}
              type='text'
              errorMessage={errors['username']}
              errorClass={'error-msg'}
            />

            <TextInput
              label='Password*'
              fieldClass={errors['password'] ? 'error-field' : 'input-field'}
              placeholder='Set a password for your school login'
              name='password'
              value={state.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
              type='password'
              errorMessage={errors['password']}
              errorClass={'error-msg'}
            />
          </form>

          <Button
            btnClass={'reg-button'}
            btnText={loading ? 'Please wait..' : 'Log In'}
            onClick={() => {
              submit();
            }}
            type='submit'
            disabled={state.username === '' || state.password === '' || loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
