import { useEffect, useState } from 'react';
import DoughnutChart from '../../components/Charts/Doughnut';
import PageLayout from '../../components/PageLayout/PageLayout';
import {
  getSchoolSummary,
  getStudentsSummary,
  getTeachersSummary,
} from '../../redux/actions/summaryActions';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { baseURL } from '../../utils/axios';
import { useFetch } from '../../hooks/useFetch';

import './schoolRecords.scss';
import CustomLegend from '../../components/CustomLegend/CustomLegend';
import HorizontalBarChart from '../../components/Charts/HorizontalBarChart';
import { ekiti_lga } from '../../data';

const SchoolRecords = () => {
  const dispatch = useDispatch();
  const [schoolState, setSchoolState] = useState('type');
  const [studentsState, setStudentsState] = useState('gender');

  useEffect(() => {
    dispatch(getSchoolSummary());
    dispatch(getStudentsSummary());
    dispatch(getTeachersSummary());
  }, []);

  const { schoolSummary, studentSummary, teachersSummary } = useSelector(
    (state) => state.summary
  );

  let schoolTypeData = [
    {
      label: 'Number of teachers',
      data: [teachersSummary?.maleTeachers, teachersSummary?.femaleTeachers],
      backgroundColor: ['#3E39DE', '#EC5EE3'],
      borderWidth: 1,
    },
  ];

  let schoolZoneData = [
    {
      label: 'Number of schools',
      data: [
        schoolSummary['Zone 1'],
        schoolSummary['Zone 2'],
        schoolSummary['Zone 3'],
        schoolSummary['Zone 4'],
        schoolSummary['Zone 5'],
      ],
      backgroundColor: ['#A5CF61', '#26A595', '#FF9E2C', '#F57070', '#F2CF8F'],
      borderWidth: 1,
    },
  ];

  let studentsGenderData = [
    {
      label: 'Number of classroms',
      data: [studentSummary?.male, studentSummary?.female, 9],
      backgroundColor: ['#4CC703', '#B8DCF9', '#FF1D1C'],
      borderWidth: 1,
    },
  ];

  let studentsWaterData = [
    {
      label: '',
      data: [9, 3],
      backgroundColor: ['#4CC703', '#FF1D1C'],
      borderWidth: 1,
    },
  ];

  let studentsFurnitureData = [
    {
      label: '',
      data: [10, 17],
      backgroundColor: ['#4CC703', '#FF1D1C'],
      borderWidth: 1,
    },
  ];

  const labels = ['Lab', 'Library', 'Hall', 'Toilet'];

  const barData = {
    labels,
    datasets: [
      {
        label: 'enrolled',
        data: [800, 543, 654, 344, 945, 700],
        backgroundColor: '#4297DD',
        borderRadius: 5,
      },
    ],
  };

  let zonesUrl = `${baseURL}/states/9/zones`;
  const zones = useFetch(zonesUrl);

  let lgaUrl = `${baseURL}/states/9/local-government-areas`;
  const lga = useFetch(lgaUrl);

  const onChange = (value, type) => {
    if (type === 'students') {
      setStudentsState(value);
    } else {
      setSchoolState(value);
    }
  };

  const onSearchChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <PageLayout currentPage={'School Records'}>
      <div className='ato-management__select-section'>
        <Select
          defaultValue='All Zones'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       e,
          //       '',
          //       '',
          //       ''
          //     )
          //   );
          // }}
          options={zones?.data?.data?.state?.zones.map((el) => ({
            value: el?.id,
            label: el?.title,
          }))}
        />

        <Select
          showSearch
          defaultValue={`All LGAs`}
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       e,
          //       '',
          //       ''
          //     )
          //   );
          // }}
          onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={ekiti_lga.map((el) => ({
            value: el?.id,
            label: el?.name,
          }))}
        />

        <Select
          defaultValue='All School Types(2)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       e,
          //       ''
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Private School',
            },
            {
              value: 2,
              label: 'Public School',
            },
          ]}
        />

        <Select
          defaultValue='All School levels (3)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       '',
          //       e
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Nursery',
            },
            {
              value: 2,
              label: 'Primary',
            },
            {
              value: 3,
              label: 'Secondary',
            },
          ]}
        />
      </div>
      <h3 className='page-title'>School Records</h3>
      <div className='summary'>
        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Classrooms</h3>
          </div>

          <div className='summary-card__chart'>
            <div className='summary-card__chart__item'>
              <DoughnutChart
                datasets={studentsGenderData}
                labels={['Usable', 'Unusable', 'Abandoned']}
              />
            </div>
            <div className='summary-card__chart__details'>
              <p>Total number of Classrooms</p>
              {/* <h3>{studentSummary?.total}</h3> */}
              <h3>223</h3>
            </div>
          </div>
          <CustomLegend
            data={[
              { id: 1, label: 'Usable classrooms', bgColor: '#4BC801' },
              { id: 2, label: 'Unusable classrooms', bgColor: '#B8DCF9' },
              { id: 3, label: 'Abandoned', bgColor: '#FF1D1C' },
            ]}
          />
        </div>

        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Special Rooms</h3>
          </div>

          <div className='summary-card__chart'>
            <div className='summary-card__chart__item' style={{ width: '60%' }}>
              <HorizontalBarChart data={barData} />
            </div>
          </div>
          <div
            className='summary-card__chart__details'
            style={{ margin: '0 auto' }}
          >
            <p>Total number of teachers</p>
            {/* <h3>{teachersSummary?.totalTeachers}</h3> */}
            <h3>279</h3>
          </div>
        </div>
      </div>

      <div className='summary'>
        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Safe drinking water</h3>
          </div>

          <div className='summary-card__chart'>
            <div className='summary-card__chart__item'>
              <DoughnutChart
                datasets={studentsWaterData}
                labels={['Usable', 'Unusable', 'Abandoned']}
              />
            </div>
            <div className='summary-card__chart__details'>
              <p>Total number of schools</p>
              {/* <h3>{studentSummary?.total}</h3> */}
              <h3>93</h3>
            </div>
          </div>
          <CustomLegend
            data={[
              { id: 1, label: 'Safe', bgColor: '#4BC801' },
              { id: 2, label: 'Not safe', bgColor: '#FF1D1C' },
            ]}
          />
        </div>

        <div className='summary-card'>
          <div className='summary-card__heading'>
            <h3 className='summary-card__title'>Students Furniture</h3>
          </div>

          <div className='summary-card__chart'>
            <div className='summary-card__chart__item'>
              <DoughnutChart
                datasets={studentsFurnitureData}
                labels={['Good', 'Bad']}
              />
            </div>

            <div className='summary-card__chart__details'>
              <p>Total number of furnitures</p>
              <h3>{teachersSummary?.totalTeachers}</h3>
            </div>
          </div>
          <CustomLegend
            data={[
              { id: 1, label: 'God Condition', bgColor: '#4BC801' },
              { id: 2, label: 'Requires Repair', bgColor: '#FF1D1C' },
            ]}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default SchoolRecords;
