import './singleschool.scss';
import Clear from '../../icons/Clear';
import Done from '../../icons/Done';

const SingleSchool = ({
  school,
  takeAction,
  showModal,
  setShowSingleSchool,
}) => {
  return (
    <div className='single-school'>
      <div className='single-school__heading'>
        <h2>{school[0]?.school?.name}</h2>
        <p>View more details about this school</p>
      </div>

      <div className='single-school__title-box'>
        <p>School Information</p>
      </div>
      <div className='single-school__school-details'>
        <div className='single-school__school-details__single'>
          <p className='single-school__school-details__single__left'>
            School Name:
          </p>
          <p className='single-school__school-details__single__right'>
            {school[0]?.school?.name}
          </p>
        </div>

        <div className='single-school__school-details__single'>
          <p className='single-school__school-details__single__left'>
            School Address:
          </p>
          <p className='single-school__school-details__single__right'>
            {school[0]?.school?.address}
          </p>
        </div>

        <div className='single-school__school-details__single'>
          <p className='single-school__school-details__single__left'>LGA:</p>
          <p className='single-school__school-details__single__right'>
            {school[0]?.school?.local_government_area?.name}
          </p>
        </div>

        <div className='single-school__school-details__single'>
          <p className='single-school__school-details__single__left'>SVC:</p>
          <p className='single-school__school-details__single__right'>
            {school[0]?.approval?.code || 'Not Available'}
          </p>
        </div>
        <div className='single-school__school-details__single'>
          <p className='single-school__school-details__single__left'>
            ATO Status:
          </p>
          <div className={`badge ${school[0]?.status?.toLowerCase()}`}>
            {school[0]?.status}
          </div>
        </div>
      </div>

      {school[0]?.file && (
        <>
          <div style={{ marginTop: '22px' }}>
            <p className='single-school__school-details__single__left'>
              Approval to Operate Document:
            </p>
            <div className='single-school__ato'>
              <p>uploaded Ato</p>
            </div>
          </div>
          <p
            className='download-doc'
            onClick={() => window.open(school[0]?.file, '_blank')}
          >
            View document
          </p>
        </>
      )}

      {school[0]?.status?.toLowerCase() === 'pending' && (
        <div className='single-school__btn-flex'>
          <button
            className='approve-button'
            onClick={() => {
              takeAction('approve', school[0]?.id);
            }}
          >
            <div className='btn-flex'>
              <Done color={'#fff'} />
              Approve ATO
            </div>
          </button>
          <button
            className='reject-button'
            onClick={() => {
              setShowSingleSchool(false);
              showModal(school[0]?.id);
            }}
          >
            <div className='btn-flex'>
              <Clear />
              Reject ATO
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleSchool;
