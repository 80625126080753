import React from 'react';

const RadioChecked = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_146_655)'>
        <path
          d='M8.00016 1.33333C4.32016 1.33333 1.3335 4.31999 1.3335 8C1.3335 11.68 4.32016 14.6667 8.00016 14.6667C11.6802 14.6667 14.6668 11.68 14.6668 8C14.6668 4.31999 11.6802 1.33333 8.00016 1.33333ZM8.00016 13.3333C5.0535 13.3333 2.66683 10.9467 2.66683 8C2.66683 5.05333 5.0535 2.66666 8.00016 2.66666C10.9468 2.66666 13.3335 5.05333 13.3335 8C13.3335 10.9467 10.9468 13.3333 8.00016 13.3333Z'
          fill='#4B8E64'
        />
        <path
          d='M7.99984 11.3333C9.84079 11.3333 11.3332 9.84095 11.3332 8C11.3332 6.15906 9.84079 4.66667 7.99984 4.66667C6.15889 4.66667 4.6665 6.15906 4.6665 8C4.6665 9.84095 6.15889 11.3333 7.99984 11.3333Z'
          fill='#4B8E64'
        />
      </g>
      <defs>
        <clipPath id='clip0_146_655'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RadioChecked;
