import { useEffect, useState } from 'react';
import DoughnutChart from '../../components/Charts/Doughnut';
import PageLayout from '../../components/PageLayout/PageLayout';
import { getResultSummary } from '../../redux/actions/resultActions';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Spin } from 'antd';
import { baseURL } from '../../utils/axios';
import { useFetch } from '../../hooks/useFetch';

import './studentsResults.scss';
import CustomLegend from '../../components/CustomLegend/CustomLegend';

import PerformanceChart from './PerformanceChart';
import { ekiti_lga, total_students } from '../../data';

const StudentsResults = () => {
  const dispatch = useDispatch();
  const [schoolState, setSchoolState] = useState('type');
  const [studentsState, setStudentsState] = useState('gender');

  useEffect(() => {
    dispatch(getResultSummary());
  }, []);

  const { loading, resultAnalytics } = useSelector((state) => state.results);

  let schoolTypeData = [
    {
      label: 'Number of teachers',
      data: [10, 20],
      backgroundColor: ['#3E39DE', '#EC5EE3'],
      borderWidth: 1,
    },
  ];

  let schoolZoneData = [
    {
      label: 'Number of schools',
      data: [resultAnalytics?.cumulativeGrades?.A, 6, 7, 8, 9, 2],
      backgroundColor: ['#A5CF61', '#26A595', '#FF9E2C', '#F57070', '#F2CF8F'],
      borderWidth: 1,
    },
  ];

  // let cummulativeResultData = [
  //   {
  //     label: 'Number of students',
  //     data: [
  //       resultAnalytics?.cumulativeGrades?.A,
  //       resultAnalytics?.cumulativeGrades?.B,
  //       resultAnalytics?.cumulativeGrades?.C,
  //       resultAnalytics?.cumulativeGrades?.D,
  //       resultAnalytics?.cumulativeGrades?.E,
  //       resultAnalytics?.cumulativeGrades?.F,
  //     ],
  //     backgroundColor: [
  //       '#4BC801',
  //       '#42A2EF',
  //       '#B8DCF9',
  //       '#FFE711',
  //       '#FB9A99',
  //       '#FF5858',
  //     ],
  //     borderWidth: 1,
  //   },
  // ];

  let cummulativeResultData = [
    {
      label: 'Number of students',
      data: [85, 117, 252, 160, 62, 26],
      backgroundColor: [
        '#4BC801',
        '#42A2EF',
        '#B8DCF9',
        '#FFE711',
        '#FB9A99',
        '#FF5858',
      ],
      borderWidth: 1,
    },
  ];

  const labels = [
    'District I',
    'District II',
    'District III',
    'District IV',
    'District V',
    'District VI',
  ];

  const barData = {
    labels,
    datasets: [
      {
        label: 'enrolled',
        data: [800, 543, 654, 344, 945, 700],
        backgroundColor: '#4297DD',
        borderRadius: 5,
      },
    ],
  };

  let zonesUrl = `${baseURL}/states/9/zones`;
  const zones = useFetch(zonesUrl);

  let lgaUrl = `${baseURL}/states/9/local-government-areas`;
  const lga = useFetch(lgaUrl);

  const onChange = (value, type) => {
    if (type === 'students') {
      setStudentsState(value);
    } else {
      setSchoolState(value);
    }
  };

  const onSearchChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <PageLayout currentPage={'Summary'}>
      <div className='ato-management__select-section'>
        <Select
          defaultValue='All Zones'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       e,
          //       '',
          //       '',
          //       ''
          //     )
          //   );
          // }}
          options={zones?.data?.data?.state?.zones.map((el) => ({
            value: el?.id,
            label: el?.title,
          }))}
        />

        <Select
          showSearch
          defaultValue={`All LGAs`}
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       e,
          //       '',
          //       ''
          //     )
          //   );
          // }}
          onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={ekiti_lga.map((el) => ({
            value: el?.id,
            label: el?.name,
          }))}
        />

        <Select
          defaultValue='All School Types(2)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       e,
          //       ''
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Private School',
            },
            {
              value: 2,
              label: 'Public School',
            },
          ]}
        />

        <Select
          defaultValue='All School levels (3)'
          style={{
            width: 120,
          }}
          optionFilterProp='children'
          // onChange={(e) => {
          //   dispatch(
          //     filterAtoRequests(
          //       currentPage,
          //       activeFilter !== 'all' ? activeFilter : '',
          //       '',
          //       '',
          //       '',
          //       e
          //     )
          //   );
          // }}
          options={[
            {
              value: 1,
              label: 'Nursery',
            },
            {
              value: 2,
              label: 'Primary',
            },
            {
              value: 3,
              label: 'Secondary',
            },
          ]}
        />
      </div>
      <h3 className='page-title'>Results</h3>
      <div className='summary-card-full'>
        <div className='summary-card-full__title'>
          <h3>Cummulative school grades</h3>
          <select>
            <option>All Classes</option>
          </select>
        </div>
        <div className='summary-card-full__chart-wrap-result'>
          <div className='summary-card-full__chart-wrap-result__chart-doughnut'>
            <div className='summary-card__chart__item'>
              <DoughnutChart
                datasets={cummulativeResultData}
                labels={['A', 'B', 'C', 'D', 'E']}
              />
            </div>
          </div>

          <div className='summary-card-full__chart-wrap__details'>
            <div className='summary-card-full__chart-wrap__details__item'>
              <p>Total number of enroled students</p>
              {/* <h3>{resultAnalytics?.students}</h3> */}
              <h3>{total_students.toLocaleString()}</h3>
            </div>

            <div className='summary-card-full__chart-wrap__details__item'>
              <p>Grade above 50%</p>
              {loading ? <Spin /> : <h3>1,404,491</h3>}
            </div>

            <div className='summary-card-full__chart-wrap__details__item'>
              <p>Grade below 50%</p>
              {loading ? <Spin /> : <h3>1,604,492</h3>}
            </div>
          </div>
        </div>
        <CustomLegend
          data={[
            { id: 1, label: 'A', bgColor: '#4BC801' },
            { id: 2, label: 'B', bgColor: '#42A2EF' },
            { id: 3, label: 'C', bgColor: '#B8DCF9' },
            { id: 4, label: 'D', bgColor: '#FFE711' },
            { id: 5, label: 'E', bgColor: '#FB9A99' },
            { id: 6, label: 'F', bgColor: '#FF5858' },
          ]}
        />
      </div>
      <PerformanceChart />
    </PageLayout>
  );
};

export default StudentsResults;
