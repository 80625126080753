import * as services from '../../services/summaryService';
import * as actionTypes from './actionTypes';
import { toast } from 'react-hot-toast';

//auth dispatch
export const getStart = () => {
  return {
    type: actionTypes.GET_SUMMARY_START,
  };
};

export const getFail = (error) => {
  return {
    type: actionTypes.GET_SUMMARY_FAIL,
    payload: {
      error,
    },
  };
};

export const getSuccess = (schoolSummary) => {
  return {
    type: actionTypes.GET_SUMMARY_SUCCESS,
    payload: {
      schoolSummary,
    },
  };
};

export const getStudentSuccess = (studentSummary) => {
  return {
    type: actionTypes.GET_STUDENT_SUMMARY_SUCCESS,
    payload: {
      studentSummary,
    },
  };
};

export const getTeachersSuccess = (teachersSummary) => {
  return {
    type: actionTypes.GET_TEACHERS_SUMMARY_SUCCESS,
    payload: {
      teachersSummary,
    },
  };
};

//actions
export const getSchoolSummary = () => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.getSummary('schools');
      dispatch(getSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch summary'
      );
      dispatch(getFail(error.response.data.data.error));
    }
  };
};

export const getStudentsSummary = () => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.getSummary('students');
      dispatch(getStudentSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch summary'
      );
      dispatch(getFail(error.response.data.data.error));
    }
  };
};

export const getTeachersSummary = () => {
  return async (dispatch) => {
    dispatch(getStart());
    try {
      const response = await services.getSummary('teachers');
      dispatch(getTeachersSuccess(response.data));
    } catch (error) {
      dispatch(getFail('an error occured'));
      toast.error(
        error?.response?.data?.data?.error || 'Unable to fetch summary'
      );
      dispatch(getFail(error.response.data.data.error));
    }
  };
};
