import { useState, useEffect, useRef } from 'react';
import RadioChecked from '../../icons/RadioChecked';
import RadioUnchecked from '../../icons/RadioUnchecked';
import './selectDropdown.scss';

const SelectDropdown = ({
  placeHolder,
  options,
  onSelectValue,
  selectedValue,
  name,
  isSearchable,
  searchValue,
  handleSearchValue,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const searchRef = useRef();

  //handle menu open and close
  useEffect(() => {
    const handler = () => setShowMenu(false);
    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  //focus on search input when showMenu value changes to true
  useEffect(() => {
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  //filter options by search value
  const getOptions = () => {
    if (!searchValue) {
      return options;
    }
    return options?.filter(
      (option) =>
        option.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const isSelected = (option) => {
    if (!isSelected) {
      return false;
    }

    return selectedValue === option;
  };

  const Icon = () => {
    return (
      <svg
        width='4'
        height='8'
        viewBox='0 0 4 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.666504 7.3335L3.99984 4.00016L0.666504 0.66683L0.666504 7.3335Z'
          fill='#323232'
        />
      </svg>
    );
  };

  return (
    <div className='dropdown-container'>
      <div className='dropdown-input' onClick={handleClick}>
        {selectedValue ? (
          <div className='dropdown-selected-value'>{selectedValue}</div>
        ) : (
          <div className='dropdown-selected-placeholder'>{placeHolder}</div>
        )}
        <div className='dropdown-tools'>
          <div className='dropdown-tool'>
            <Icon />
          </div>
        </div>
      </div>
      {showMenu && (
        <div className='dropdown-menu'>
          {isSearchable && options && (
            <div className='dropdown-menu__search-box'>
              <input
                value={searchValue}
                onChange={handleSearchValue}
                ref={searchRef}
                placeholder='Search'
              />
            </div>
          )}
          {options ? (
            getOptions()?.map((el) => (
              <div
                key={el?.id}
                className={`dropdown-item ${
                  isSelected(el?.value) && 'selected'
                }`}
                onClick={() => {
                  onSelectValue(
                    name === 'action'
                      ? el?.description
                      : name === 'schoolZone' || name === 'gender'
                      ? el.title
                      : el?.label,
                    name
                  );
                  name === 'action' && localStorage.setItem('actionId', el?.id);
                }}
              >
                {isSelected(
                  name === 'action'
                    ? el?.description
                    : name === 'schoolZone' || name === 'gender'
                    ? el.title
                    : el?.value
                ) ? (
                  <RadioChecked />
                ) : (
                  <RadioUnchecked />
                )}
                <p>
                  {' '}
                  {name === 'action'
                    ? el?.description
                    : name === 'schoolZone' || name === 'gender'
                    ? el.title
                    : el?.label}{' '}
                </p>
              </div>
            ))
          ) : (
            <div
              style={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p>No options available</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
