import React from 'react';
import LineChart from '../../components/Charts/LineChart';
import CustomLegend from '../../components/CustomLegend/CustomLegend';
import { useNavigate } from 'react-router';

const PerformanceChart = () => {
  const navigate = useNavigate();
  const labels = [
    'Wk 1',
    'Wk 2',
    'Wk 3',
    'Wk 4',
    'Wk 5',
    'Wk 6',
    'Wk 7',
    'Wk 8',
    'Wk 9',
    'Wk 10',
    'Wk 11',
    'Wk 12',
    'Wk 13',
    'Wk 14',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Mathematics',
        data: [26, 20, 23, 39, 43, 45, 53, 49, 32, 63, 69, 75, 82, 90],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return (
    <div className='summary-card-full'>
      <div className='summary-card-full__title'>
        <h3>Overall performance of students</h3>
        <div className='summary-card-full__title__flex'>
          <select>
            <option>Mathematics</option>
            <option>English</option>
          </select>
          <select>
            <option>SS3 WAEC</option>
          </select>
        </div>
      </div>
      <div className='summary-card-full__chart-wrap-result'>
        <LineChart data={data} />
      </div>

      <div className='performance-mark'>
        <div className='performance-mark__item'>
          <p>Total number of students in WAEC class</p>
          <h3 className='performance-mark__item__1'>1,662,440</h3>
        </div>
        <div className='performance-mark__item'>
          <p>Grade above 50%</p>
          <h3 className='performance-mark__item__2'>1,273,400</h3>
        </div>
        <div className='performance-mark__item'>
          <p>Grade below 50%</p>
          <h3 className='performance-mark__item__3'>389,040</h3>
        </div>
      </div>

      <div className='recommendation-btn'>
        <button onClick={() => navigate('/recommendation')}>
          View Recommendation For Performance Improvement
        </button>
      </div>
    </div>
  );
};

export default PerformanceChart;
