import React from 'react';

const View = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_284_5498)'>
        <path
          d='M7.99935 3.99984C10.526 3.99984 12.7793 5.41984 13.8793 7.6665C12.7793 9.91317 10.526 11.3332 7.99935 11.3332C5.47268 11.3332 3.21935 9.91317 2.11935 7.6665C3.21935 5.41984 5.47268 3.99984 7.99935 3.99984ZM7.99935 2.6665C4.66602 2.6665 1.81935 4.73984 0.666016 7.6665C1.81935 10.5932 4.66602 12.6665 7.99935 12.6665C11.3327 12.6665 14.1793 10.5932 15.3327 7.6665C14.1793 4.73984 11.3327 2.6665 7.99935 2.6665ZM7.99935 5.99984C8.91935 5.99984 9.66601 6.7465 9.66601 7.6665C9.66601 8.5865 8.91935 9.33317 7.99935 9.33317C7.07935 9.33317 6.33268 8.5865 6.33268 7.6665C6.33268 6.7465 7.07935 5.99984 7.99935 5.99984ZM7.99935 4.6665C6.34602 4.6665 4.99935 6.01317 4.99935 7.6665C4.99935 9.31984 6.34602 10.6665 7.99935 10.6665C9.65268 10.6665 10.9993 9.31984 10.9993 7.6665C10.9993 6.01317 9.65268 4.6665 7.99935 4.6665Z'
          fill='#010C15'
          fillOpacity='0.7'
        />
      </g>
      <defs>
        <clipPath id='clip0_284_5498'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default View;
