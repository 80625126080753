export const recommendations = [
  { id: 1, title: 'Adjuncts', subResult: [] },
  { id: 2, title: 'Concord', subResult: [] },
  { id: 3, title: 'Consonant Cluster', subResult: [] },
  { id: 4, title: 'Emphatic Stress', subResult: [] },
  {
    id: 5,
    title: 'Essay: Descriptive',
    p1: 'Improving the preparation of 18-year-old Nigerian students for writing descriptive essays in the English Language is essential for enhancing their communication skills and critical thinking abilities. Here are three recommendations that government and policymakers can implement to achieve this:',
    p2: 'By implementing these recommendations, the Nigerian government and policymakers can enhance the quality of English Language education, equipping 18-year-old students with the skills necessary to answer tough descriptive essay questions effectively. This approach not only prepares them academically but also fosters critical thinking, creativity, and communication abilities that are valuable in various aspects of their lives.',
    subResult: [
      {
        id: 1,
        heading: 'Incorporate Real-World Descriptive Writing Projects',
        body: 'To prepare students for descriptive essay writing, the curriculum should include real-world projects that encourage students to describe their immediate environment, communities, or personal experiences. For instance, students can be tasked with describing local landmarks, cultural festivals, historical sites, or even their own family traditions. This hands-on approach not only makes learning more engaging but also helps students connect with their subjects, fostering a deeper understanding of descriptive writing.',
      },
      {
        id: 2,
        heading: 'Promote Cross-Disciplinary Learning',
        body: 'Encourage cross-disciplinary learning by integrating descriptive writing into subjects beyond English Language. Collaborative projects with history, geography, science, and social studies can expose students to diverse topics that require descriptive skills. For example, students can write descriptive essays on historical figures, geographical landscapes, scientific phenomena, or societal issues. This approach helps students apply their descriptive writing skills in various contexts, broadening their horizons and fostering critical thinking.',
      },
      {
        id: 3,
        heading: 'Emphasize Peer Review and Feedback',
        body: 'Implement a system of peer review and constructive feedback in English Language classrooms. Students can exchange their descriptive essays with classmates and provide feedback on the clarity, vividness, and coherence of descriptions. This process not only improves their own writing skills but also encourages collaboration and a deeper understanding of descriptive writing techniques. Teachers can guide and facilitate these peer review sessions, ensuring that students learn from each other`s strengths and weaknesses.',
      },
    ],
  },
  { id: 6, title: 'Farm Register: Religion, Education', subResult: [] },
  {
    id: 7,
    title: 'Intonation: Rising Tune',
    p1: 'Improving the teaching and understanding of intonation, specifically rising tune, in the Nigerian English Language curriculum is crucial for students to communicate effectively. Here are three deep and relevant learning improvement ideas for policymakers to consider:',
    p2: 'By implementing these three learning improvement ideas, the Nigerian government can enhance the teaching and learning of intonation, particularly rising tune, in the English Language curriculum. This will enable 18-year-old students to confidently answer tough questions related to this topic and improve their overall communication skills in both formal and informal settings.',

    subResult: [
      {
        id: 1,
        heading: 'Comprehensive Teacher Training Programs',
        body: 'To ensure the effective delivery of intonation concepts, the government should invest in comprehensive teacher training programs. These programs should focus on equipping English Language teachers with a deep understanding of intonation, including rising tune, its importance, and practical teaching techniques. This training can be conducted through workshops, seminars, and online courses, with a specific emphasis on real-world examples and interactive teaching methods. This will empower teachers to convey the nuances of rising tune effectively to their students.',
      },
      {
        id: 2,
        heading: 'Digital Learning Resources and Multimedia Tools',
        body: 'In today`s digital age, integrating multimedia tools and online resources into the curriculum can greatly enhance students` learning experiences. Policymakers should collaborate with educational technology companies to develop interactive digital materials, including video lessons, audio recordings, and interactive quizzes, that focus on intonation and rising tune in the English Language. These resources should align with the Nigerian curriculum and cater to diverse learning styles, making it easier for 18-year-old students to grasp the concept of rising tune in a practical context.',
      },
      {
        id: 3,
        heading: 'Practical Application and Performance Assessments',
        body: 'To ensure that students not only understand rising tune but can also apply it effectively, the curriculum should include practical assessments. Policymakers can introduce oral examinations, debate competitions, and group discussions that require students to demonstrate their understanding of rising tune in real-life communication scenarios. This approach will encourage students to actively practice and incorporate rising tune into their spoken English. Additionally, incorporating rising tune in the assessment criteria for standardized tests can incentivize schools to prioritize its teaching.',
      },
    ],
  },
  { id: 8, title: 'Rhyme', subResult: [] },
  { id: 9, title: 'Sounds: Tripthongs', subResult: [] },
  { id: 10, title: 'Summary', subResult: [] },
];

export const enugu_lga = [
  { id: 1, name: 'Aninri' },
  { id: 2, name: 'Awgu' },
  { id: 3, name: 'Enugu East' },
  { id: 4, name: 'Enugu North' },
  { id: 5, name: 'Enugu South' },
  { id: 6, name: 'Igbo Etit' },
  { id: 7, name: 'Igbo Eze North' },
  { id: 8, name: 'Igbo Eze South' },
  { id: 9, name: 'Isi Uzo' },
  { id: 10, name: 'Nkanu East' },
  { id: 11, name: 'Nkanu West' },
  { id: 12, name: 'Nsukka' },
  { id: 13, name: 'Oji River' },
  { id: 14, name: 'Udenu' },
  { id: 15, name: 'Udi' },
  { id: 1, name: 'Uzo - Uwani' },
];

export const ekiti_lga = [
  { id: 1, name: 'Ado-Ekiti' },
  { id: 2, name: 'Aiyekire' },
  { id: 3, name: 'Ekiti South West' },
  { id: 4, name: 'Ido-Osi' },
  { id: 5, name: 'Ilejemeje' },
  { id: 6, name: 'Moba' },
  { id: 7, name: 'Ikere' },
  { id: 8, name: 'Efon' },
  { id: 9, name: 'Ekiti West' },
  { id: 10, name: 'Ijero' },
  { id: 11, name: 'Irepodun/Ifelodun' },
  { id: 12, name: 'Oye' },
  { id: 13, name: 'Ekiti East' },
  { id: 14, name: 'Emure' },
  { id: 15, name: 'Ikole' },
  { id: 1, name: 'Ise/Orun' },
];

export const total_students = 3008983;

export const total_male_students = 1608943;
export const total_female_students = 1400040;
