import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes } from './routes';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {Object.values(routes).map((el) => (
            <Route
              exact
              path={el.path}
              key={el.id}
              element={<el.component />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
