import * as actionTypes from '../actions/actionTypes';

const initialState = {
  schoolSummary: {},
  studentSummary: {},
  teachersSummary: {},
  error: null,
  loading: false,
};

const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUMMARY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case actionTypes.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        schoolSummary: action.payload.schoolSummary,
        loading: false,
        error: null,
      };

    case actionTypes.GET_STUDENT_SUMMARY_SUCCESS:
      return {
        ...state,
        studentSummary: action.payload.studentSummary,
        loading: false,
        error: null,
      };

    case actionTypes.GET_TEACHERS_SUMMARY_SUCCESS:
      return {
        ...state,
        teachersSummary: action.payload.teachersSummary,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default summaryReducer;
