import React from 'react';

const InsertIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_7_911)'>
        <path
          d='M14.5 2H6.5C5.4 2 4.51 2.9 4.51 4L4.5 20C4.5 21.1 5.39 22 6.49 22H18.5C19.6 22 20.5 21.1 20.5 20V8L14.5 2ZM6.5 20V4H13.5V9H18.5V20H6.5Z'
          fill='#0A6DCD'
        />
      </g>
      <defs>
        <clipPath id='clip0_7_911'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InsertIcon;
