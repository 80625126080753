import Approvals from './pages/Approvals/Approvals';
import AtoManagement from './pages/AtoManagement/AtoManagement';
import Attendance from './pages/Attendance/Attendance';
import Login from './pages/Login/Login';
import Recommendation from './pages/Recommendation/Recommendation';
import SchoolRecords from './pages/SchoolRecords/SchoolRecords';
import StudentsResults from './pages/StudentsResults/StudentsResults';
import Summary from './pages/Summary/Summary';

export const routes = [
  {
    id: 1,
    path: '/ato-management',
    component: AtoManagement,
  },
  {
    id: 2,
    path: '/summary',
    component: Summary,
  },

  {
    id: 3,
    path: '/',
    component: Login,
  },

  {
    id: 4,
    path: '/approvals',
    component: Approvals,
  },
  {
    id: 5,
    path: '/school-records',
    component: SchoolRecords,
  },
  {
    id: 6,
    path: '/attendance',
    component: Attendance,
  },
  {
    id: 7,
    path: '/students-results',
    component: StudentsResults,
  },
  {
    id: 8,
    path: '/recommendation',
    component: Recommendation,
  },
];
