import React from 'react';

const Revoke = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_212_4618)'>
        <path
          d='M7.99935 3.99984V5.99984L10.666 3.33317L7.99935 0.666504V2.6665C5.05268 2.6665 2.66602 5.05317 2.66602 7.99984C2.66602 9.0465 2.97268 10.0198 3.49268 10.8398L4.46602 9.8665C4.16602 9.31317 3.99935 8.67317 3.99935 7.99984C3.99935 5.79317 5.79268 3.99984 7.99935 3.99984ZM12.506 5.15984L11.5327 6.13317C11.826 6.69317 11.9993 7.3265 11.9993 7.99984C11.9993 10.2065 10.206 11.9998 7.99935 11.9998V9.99984L5.33268 12.6665L7.99935 15.3332V13.3332C10.946 13.3332 13.3327 10.9465 13.3327 7.99984C13.3327 6.95317 13.026 5.97984 12.506 5.15984Z'
          fill='#010C15'
          fillOpacity='0.7'
        />
      </g>
      <defs>
        <clipPath id='clip0_212_4618'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Revoke;
